































































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'uOttawa1321RawDataAttachment',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        language: 'en',
      },
      questions: [
        {
          en: 'Please attach all raw data for your lab report using the upload field below.',
          fr: 'Veuillez joindre toutes les données brutes de votre rapport de laboratoire en utilisant le champ de téléchargement ci-dessous.',
        },
      ],
      attachments: [] as File[],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
